<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <b-link class="ml-25" href="https://www.drivesense.io" target="_blank"
        >DriveSense</b-link
      >
      tarafından üretilmiştir. Her Hakkı Saklıdır.
      <b-link class="ml-25" href="https://www.turkai.com/" target="_blank"
        >TURK AI</b-link
      >'ın katkılarıyla
    </span>
  </p>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
