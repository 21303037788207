<template>
  <sensor-dashboard
    :sensors="sensors"
    :sensorId="sensorId"
    @sensor-selected="sensorSelected"
  />
</template>

<script>
import { mapGetters } from "vuex";

import SensorDashboard from "@/components/views/SensorDashboard.vue";

export default {
  components: {
    SensorDashboard,
  },
  data() {
    return { sensorId: null, firstLoad: true };
  },
  watch: {
    $route(to, from) {
      this.sensorId = to.params.id;
      this.redirectOnFirstLoad();
    },
  },
  computed: {
    ...mapGetters(["sensors", "sensorById"]),
    containerClass() {
      let classes = ["layout"];

      if (this.sensorId) {
        classes.push("has-sensor-info");

        if (
          this.sensorById(this.sensorId) &&
          this.sensorById(this.sensorId).organization
        ) {
          classes.push("has-organization");
        }
      }

      return classes.join(" ");
    },
  },
  methods: {
    redirectOnFirstLoad() {
      if (this.sensorId) {
        this.firstLoad = false;
      }
      if (this.firstLoad && !this.sensorId && this.sensors.length) {
        this.$router.push(`/${this.sensors[0].id}`);
        this.firstLoad = false;
      }
    },
    sensorSelected(id) {
      this.$router.push(`/${id}`);
    },
  },
  async mounted() {
    this.sensorId = this.$route.params.id;
  },
};
</script>

<style>
</style>


<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: min-content min-content min-content min-content;
}

.layout div {
  margin: 0.5rem 0;
}

.layout {
  .organization {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  .sensor-name {
    grid-column: 1/5;
    grid-row: 2/3;
  }

  .stat-bike {
    grid-column: 1/5;
    grid-row: 4/5;
  }
  .stat-walk {
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .map {
    grid-row: 6/7;
    grid-column: 1/5;
  }

  .sensor-info {
    grid-row: 7/8;
    grid-column: 1/5;
  }

  .bar-chart {
    grid-row: 8/9;
    grid-column: 1/5;
  }
}

@media only screen and (min-width: 1020px) {
  .layout div {
    margin: 1rem;
  }
  .sensor-name {
    display: none;
  }
  .layout {
    .map {
      grid-column: 1/3;
      grid-row: 1/3;
    }

    .stat-bike {
      grid-column: 3/4;
      grid-row: 1/2;
    }

    .stat-walk {
      grid-column: 4/5;
      grid-row: 1/2;
    }

    .bar-chart {
      grid-column: 3/5;
      grid-row: 2/4;
    }
  }

  .layout.has-organization {
    .organization {
      grid-column: 1/3;
      grid-row: 1/2;
    }
    .map {
      grid-column: 1/3;
      grid-row: 2/4;
    }
  }

  .layout.has-sensor-info {
    .sensor-info {
      grid-column-start: 3;
      grid-column-end: 3;
      grid-row: 1/3;
    }

    .stat-bike {
      grid-column: 4/5;
      grid-row: 1/2;
    }

    .stat-walk {
      grid-column: 4/5;
      grid-row: 2/3;
    }

    .bar-chart {
      grid-column: 3/5;
      grid-row: 3/5;
    }
  }
}
</style>


<style>
.b-avatar-custom .material-design-icon {
  display: block;
  width: 2rem;
  height: 2rem;
}
.b-avatar-custom .material-design-icon__svg {
  width: 2rem !important;
  height: 2rem !important;
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.sensor-info-card {
  height: 495px;
}

.sensor-info-card img {
  flex-grow: 5;
  object-fit: cover;
  object-position: center;
}
</style>