<template>
  <div class="navbar-container d-flex content align-items-center">
    <div class="bookmark-wrapper align-items-center flex-shrink d-lg-flex">
      <div class="dark-toggle-container">
        <dark-Toggler class="d-lg-block" />
      </div>
    </div>
    <!-- Left Col -->
    <h3 class="brand-text mb-0 flex-grow-1 text-center">
      {{ appName }}
    </h3>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-shrink d-lg-flex">
      <div class="dark-toggle-container">
        <router-link to="/admin" v-slot="{ href }">
          <b-nav-item :href="href">
            <feather-icon size="21" icon="LogInIcon" />
          </b-nav-item>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { $themeConfig } from "@themeConfig";

export default {
  name: "DashboardNavbar",
  components: { DarkToggler },
  setup() {
    // App Name
    const { appName } = $themeConfig.app;
    return {
      appName,
    };
  },
};
</script>
<style scoped>
.dark-toggle-container li {
  list-style: none;
}

.brand-text {
  color: #7367f0;
  font-weight: bold;
}
</style>
