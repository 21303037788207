<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <div
      class="horizontal-layout horizontal-menu navbar-floating footer-static"
    >
      <b-navbar
        class="
          navbar
          header-navbar
          navbar-shadow
          align-items-center
          navbar-brand-center navbar-fixed
          mb-2
        "
      >
        <navbar />
      </b-navbar>
      <div class="horizontal-menu-wrapper">
        <div
          id="menu"
          class="
            header-navbar
            navbar navbar-horizontal navbar-light navbar-shadow
            menu-border
            floating-nav
            navbar-expand-sm
          "
        >
          <div class="navbar-container main-menu-content">
            <horizontal-nav-menu-items :items="navigation" />
          </div>
        </div>
      </div>
      <div style="padding-top: 11rem">
        <home />
      </div>
      <footer class="footer footer-light">
        <slot name="footer">
          <app-footer />
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import { mapActions, mapGetters } from "vuex";
import HorizontalNavMenuItems from "@/@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";

import useAppConfig from "@core/app-config/useAppConfig";
import AppFooter from "@/@core/layouts/components/AppFooter.vue";
import Home from "./Home.vue";
import Navbar from "./components/Navbar.vue";

export default {
  components: {
    LayoutFull,
    AppFooter,
    Home,
    HorizontalNavMenuItems,
    Navbar,
  },
  computed: {
    ...mapGetters(["sensorById"]),
    navigation() {
      let nav = [
        {
          header: "Ankara",
          title: "Ankara",
          icon: "",
          route: "dashboard",
        },
      ];
      const sensorId = this.$route.params.id;
      if (sensorId) {
        const sensor = this.sensorById(sensorId);
        if (sensor) {
          nav.push({
            title: sensor.name,
            icon: "",
            route: `sensor`,
          });
        }
      }
      return nav;
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    ...mapActions(["fetchSensors"]),
  },
  async mounted() {},
  setup() {
    const { contentWidth } = useAppConfig();
    return { contentWidth };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>

<style>
#menu {
  display: block !important;
  width: calc(100% - 2rem) !important;
  margin: 0 1rem;
}
</style>